import ApplicationModel from './ApplicationModel';
import { ClassName } from '../types/types';
import { ProductJSON } from '../types/json_types';
import ProductImage from './ProductImage';
import Upgrade from './Upgrade';
import { getModels } from '../constants/globals';
import School from './School';
import { findModelByAttr } from '../util/app_util';

class Product extends ApplicationModel {
  name: string;
  short: string;
  short_short: string;
  product_images: ProductImage[];
  base_price_cents: number;
  wm_price_cents: number | null;
  richmond_price_cents: number | null;
  methodist_price_cents: number | null;
  bland_price_cents: number | null;
  sku: number;
  needs_insurance: boolean;
  new_required: boolean;
  description: string;
  upgrades?: Upgrade[];

  constructor(json: ProductJSON, product_images?: ProductImage[]) {
    super(json);

    this.name = json.name;
    this.description = json.description;
    this.short = json.short;
    this.short_short = json.short_short;
    this.product_images = product_images || [];
    this.base_price_cents = json.base_price_cents;
    this.wm_price_cents = json.wm_price_cents;
    this.richmond_price_cents = json.richmond_price_cents;
    this.methodist_price_cents = json.methodist_price_cents;
    this.bland_price_cents = json.bland_price_cents;
    this.sku = json.sku;
    this.needs_insurance = json.needs_insurance;
    this.new_required = this.short === 'FFC';
  }

  getCents(school: School): number | null {
    if (school.isMethodist()) {
      return this.methodist_price_cents;
    } else if (school.isRichmond()) {
      return this.richmond_price_cents;
    } else if (school.isBland()) {
      return this.bland_price_cents;
    } else {
      return this.wm_price_cents;
    }
  }

  showDescription(school: School): boolean {
    if (this.short === 'BL') {
      const mt = findModelByAttr(getModels('products'), 'short', 'PM');
      return mt.getCents(school) !== null;
    } else if (this.short === 'PM' && school.short === 'methodist') {
      return false;
    } else {
      return true;
    }
  }

  hasSchool(school: School): boolean {
    return this.getCents(school) !== null;
  }

  getRadioGroup(school: School): Upgrade[] {
    return getModels('upgrades').filter((u): boolean => {
      return u.radio && u.product_id === this.id && school.hasUpgrade(u);
    });
  }

  getUpgrades(school: School): Upgrade[] {
    if (this.upgrades) {
      return this.upgrades;
    } else {
      this.upgrades = getModels('upgrades').filter((u): boolean => {
        return !u.radio && u.product_id === this.id && school.hasUpgrade(u);
      });
      return this.upgrades;
    }
  }

  getImages(): string[] {
    return this.product_images.map((pi: ProductImage) => {
      return pi.getUrl('thumb');
    });
  }

  getClassName(): ClassName {
    const className: ClassName = 'Product';
    return className;
  }
}

export default Product;
