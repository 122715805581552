import { InvoiceStatus } from '../models/Invoice';
import { SchoolShort } from '../models/School';

export interface BuildingJSON extends ModelJSON {
  name: string;
  shown_name: string;
  school_id: number;
}

export type BuildingsJSONResponse = {
  buildings: BuildingJSON[];
};

export interface ProductJSON extends ModelJSON {
  name: string;
  short: string;
  short_short: string;
  description: string;
  base_price_cents: number;
  methodist_price_cents: number | null;
  wm_price_cents: number | null;
  richmond_price_cents: number | null;
  bland_price_cents: number | null;
  sku: number;
  needs_insurance: boolean;
}

export interface ProductImageJSON extends ModelJSON {
  product_id: number;
  image_file_name: string;
}

export interface ModelJSON {
  id: number;
  created_at: number;
}

export interface UpgradeJSON extends ModelJSON {
  radio: boolean;
  product_id: number;
  name: string;
  short: string;
  label: string;
  description: string;
  sku: number;
  methodist_price_cents: number | null;
  wm_price_cents: number | null;
  richmond_price_cents: number | null;
  bland_price_cents: number | null;
  addon: boolean;
  isnew: boolean;
}

export interface SchoolJSON extends ModelJSON {
  name: string;
  short: SchoolShort;
}

export type ErrorResponse = {
  errors: Array<string>;
};

export type AllJSONResponse = {
  upgrades: Array<UpgradeJSON>;
  schools: Array<SchoolJSON>;
  products: Array<ProductJSON>;
  product_images: Array<ProductImageJSON>;
  buildings: BuildingJSON[];
};

export type ReservationStatus = 'CREATED';
export type SchoolYear = 'Freshman' | 'Sophmore' | 'Junior' | 'Senior' | 'Graduate';

export interface ReservationJSON extends ModelJSON {
  student_first_name: string;
  student_last_name: string;
  student_phone: string;
  student_email: string;
  year: SchoolYear;
  school_id: number;
  building_id: number;
  room_number: string;
  room_wing: string;
  special_instructions: string;
  arrival_date_at: string | null;
  arriving_prior: boolean;
  arrival_unknown: boolean;
  parent_first_name: string;
  parent_last_name: string;
  parent_phone: string | null;
  parent_email: string;
  status: ReservationStatus | null;
  archived: boolean | null;
  departure_date?: string;
}

export interface InvoiceJSON extends ModelJSON {
  status: InvoiceStatus;
  reservation_id: number;
  token: string;
  pay_w_cash: boolean;
  fee_cents: number | null;
  final_charge_cents: number | null;
  total_cents: number | null;
  funding_type: 'CREDIT' | 'DEBIT' | null;
}

export type ArrItem = {
  label: string;
  quantity: number;
  cents: number;
};

export type CalcLineItem = {
  subtotal: number;
  key: string;
  arr: ArrItem[];
};

export type SuccessfulStripePaymentResp = {
  invoice: InvoiceJSON;
  calc: CalcResp;
};

export type ChargeCalc = {
  key: string;
  label: string;
  price_cents: number;
};

export type CalcResp = {
  line_items: CalcLineItem[];
  charges: ChargeCalc[];
  subtotal: number;
  tax: number;
  total: number;
  fee: number;
  final_charge_cents: number;
};

export type InvoiceApiResp = {
  base: {
    reservation: ReservationJSON;
    invoice: InvoiceJSON;
  };
  calc: CalcResp;
};

export type TableName = keyof AllJSONResponse | 'reservations' | 'invoices';

export function isErrorResponse<T>(json: T | ErrorResponse): json is ErrorResponse {
  return (json as ErrorResponse).errors !== undefined;
}

// export function isNull(str: string | null): str is null {
//   return str == null;
// }
//
