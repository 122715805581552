import React from 'react';
import { cart, store, line_items_key } from '../../constants/globals';
import LineItem from '../../models/LineItem';
import ArrivalDateSection from './ArrivalDateSection';
import { Typography, Button, TextField, Link } from '@material-ui/core';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DotIcon from '@material-ui/icons/FiberManualRecord';
import ApiRequest from '../../libs/ApiRequest';
import { isErrorResponse } from '../../types/json_types';
import { router } from '../../init_app/store';

const clickBack = (): void => {
  store.setState('viewShown', 'ParentDetails');
};

const { getNonNullState: getState, setPartialState: setState, useNonNullState: useFinal } = store.getHelpers('final');

const renderItem = (li: LineItem): JSX.Element => {
  return (
    <div key={li.key()} className="d-flex align-items-center">
      <DotIcon style={{ width: 8 }} className="mr-1" />
      <div>{li.getLabel()} </div>
    </div>
  );
};

const clickReserve = (): void => {
  if (getState().working) {
    return;
  }

  const reservation = { school_id: store.getNonNullState('school').id };
  const student = window.App.studentDeets;
  const state = getState();
  const date = state.arrival_date_at;
  const arrival_str = date ? `${date.getMonth() + 1}/${date.getDate()}` : null;
  Object.assign(reservation, window.App.parentDeets, student, state, {
    building_id: student?.building_id === 'null' ? null : student?.building_id,
    arrival_str,
  });
  const line_items = cart.toJSON();
  const req = new ApiRequest(
    '/store_api/reservations.json',
    'POST',
    {
      onSuccess: (): void => {
        localStorage.removeItem(line_items_key);
        store.getStateObj().cart_count = 0;
        router.go('/success');
      },
      onError: (json: unknown): void => {
        if (isErrorResponse(json)) {
          alert(json.errors.join(' &'));
        } else {
          alert('an error occurred');
        }
      },
      onDone: (): void => {
        setState({ working: false });
      },
    },
    { reservation, line_items },
  );

  setState({ working: true });
  req.perform();
  console.log('clickReserve');
};

const handleDateChange = (date: Date | null): void => {
  setState({ arrival_date_at: date });
};

const onChangeBool = (arriving_prior: boolean | null): void => {
  setState({ arriving_prior });
};

const Final = (): JSX.Element => {
  const { arrival_date_at, arrival_unknown, arriving_prior, working } = useFinal();

  const line_items = cart.line_items;

  const selected_date = !!arrival_date_at;
  const selected_unknown = arrival_unknown;
  const btn_enabled = !working && (selected_date || selected_unknown);

  return (
    <div className="p-1" style={{ maxWidth: 600, margin: 'auto' }}>
      <div className="d-flex justify-content-between mb-3 align-items-center">
        <Button color="primary" className="d-flex align-items-center" onClick={clickBack} variant="outlined">
          <ArrowBackIcon className="mr-1" />
          Back
        </Button>

        <Typography className="d-flex align-items-center" variant="h5" style={{ textAlign: 'center' }}>
          Reserve now
        </Typography>

        <Button
          disabled
          color="primary"
          className="d-flex align-items-center"
          variant="outlined"
          style={{ visibility: 'hidden' }}
        >
          <ArrowBackIcon className="mr-1" />
          Back
        </Button>
      </div>
      <div style={{ background: '#fafafa', border: '1px solid #bfbfbf', borderRadius: 5 }} className="py-2 px-1">
        <Typography variant="h6">Your Products</Typography>
        <div className="pl-2">{line_items.map(renderItem)}</div>
      </div>

      <ArrivalDateSection
        arrivingPrior={arriving_prior}
        onChangeDate={handleDateChange}
        date={arrival_date_at}
        onChangeBool={onChangeBool}
        arrivalUnknown={arrival_unknown}
      />

      <div className="mt-3">
        <TextField
          style={{ width: '100%' }}
          id="special_instructions"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e): void => setState({ special_instructions: e.target.value })}
          label="Special Instructions"
          helperText="Anything else you want to let us know about your order? Let us know here."
          multiline
          rows={4}
          variant="outlined"
        />
      </div>

      <div className="mt-2 mb-2">
        <Button
          style={{ width: '100%' }}
          onClick={clickReserve}
          size="large"
          variant="contained"
          color="primary"
          disableElevation
          disabled={!btn_enabled}
        >
          Reserve now!
        </Button>
      </div>

      <Typography className="my-2 mt-3">
        By submitting this reservation, I agree to the Dorms Direct{' '}
        <Link href="http://dormsdirect.com/tos.html" target="_blank">
          Terms of Service
        </Link>
      </Typography>
    </div>
  );
};

export default Final;
