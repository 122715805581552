import ApplicationModel from './ApplicationModel';
import { SchoolJSON, TableName } from '../types/json_types';
import { ClassName } from '../types/types';
import Upgrade from './Upgrade';

export type SchoolShort = 'wm' | 'richmond' | 'methodist' | 'bland';

class School extends ApplicationModel {
  name: string;
  short: SchoolShort;

  constructor(json: SchoolJSON) {
    super(json);

    this.name = json.name;
    this.short = json.short;
  }

  isMethodist(): boolean {
    return this.short === 'methodist';
  }

  isRichmond(): boolean {
    return this.short === 'richmond';
  }

  isWm(): boolean {
    return this.short === 'wm';
  }

  isBland(): boolean {
    return this.short === 'bland';
  }

  hasUpgrade(u: Upgrade): boolean {
    if (this.isMethodist()) {
      return u.methodist_price_cents != null;
    } else if (this.isRichmond()) {
      return u.richmond_price_cents != null;
    } else if (this.isBland()) {
      return u.bland_price_cents != null;
    } else {
      return u.wm_price_cents != null;
    }
  }

  getClassName(): ClassName {
    const className: ClassName = 'School';
    return className;
  }

  static getTableName(): TableName {
    return 'schools';
  }
}

export default School;
